import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { loadTellMeWhyListPromise } from "routines/user";

import StandardButton from "components/Buttons/StandardButton";
import TellMeWhySparkle from "components/Common/Modals/tellMeWhy/images/TellMeWhySparkle.svg";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    whiteSpace: "nowrap",
  },
  sparkle: {
    width: 16,
    height: 16,
  },
};

const ButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    height: "2.625rem",
    fontSize: 13,
    borderRadius: 25,
    background: "var(--Secondary-D4, #7D1AB7)",
    backgroundColor: "var(--Secondary-D4, #7D1AB7)",
    color: colours.white,
  },
};

const TellMeWhyButton = (props) => {
  const { podcastId } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { loadTellMeWhyPromtList } = useRoutinePromises({
    loadTellMeWhyPromtList: loadTellMeWhyListPromise,
  });
  const { showModal, closeModal } = useActionCreators({
    showModal: modalActions.showModal,
    closeModal: modalActions.closeModal,
  });

  const hasPro = useUserHasPro();
  const user = useLoggedInUser();
  const username = user?.get("username");
  const userEmail = user?.getIn(["user_data", "email"]);
  const campaigns = user?.get("campaigns");
  const step = campaigns?.size > 0 ? "choose_campaign" : "intro";
  const hasCampainPrompt = user
    ?.get("campaign_prompt_podcast_ids")
    ?.includes(podcastId);

  useEffect(() => {
    if (hasPro && !campaigns) {
      loadTellMeWhyPromtList({ podcastId });
    }
  }, [campaigns, hasPro, loadTellMeWhyPromtList, podcastId]);

  const handleTellMeWhyClick = useCallback(() => {
    sendGAEvent({
      action: "tellmewhy-launch",
      page: "Podcast Page",
      podcastId,
      username,
      userEmail,
    });

    showModal("tellMyWhyPrompt", {
      mobile,
      closeModal,
      podcastId,
      step,
    });
  }, [closeModal, mobile, podcastId, showModal, step, userEmail, username]);

  if (hasPro && hasCampainPrompt) {
    return null;
  }

  return (
    <div className={css(styles.buttonContainer)}>
      <StandardButton
        label={
          <div className={css(styles.labelWrapper)}>
            <img
              className={css(styles.sparkle)}
              src={TellMeWhySparkle}
              alt="tell me why sparkle"
            />
            <span>Tell Me Why</span>
          </div>
        }
        withPadding
        customStyles={ButtonStyles}
        onClick={handleTellMeWhyClick}
        variation="none"
        flat
      />
    </div>
  );
};

TellMeWhyButton.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

TellMeWhyButton.defaultProps = {
  podcast: null,
};

export default TellMeWhyButton;
