import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { compose } from "redux";

import { calcStarColor } from "utils/colours";
import withHover from "utils/react-with-hover";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  star: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0.2rem",
    transition:
      "color 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946), transform 200ms cubic-bezier(0.465, 0.183, 0.153, 0.946)",
    [ScreenSizes.mdAndBelow]: {
      margin: 0,
    },
  },
  starActive: {},
  starInactive: {},
  starHover: {},
  starCurrent: {},
  starEmpty: {
    opacity: 0.1,
    color: "#000",
    transform: "scale(1,1)",
  },
  starFull: {
    opacity: 1,
    transform: "scale(1.1,1.1)",
    color: colours.primary,
  },
  primaryVariation: {},

  icon: {},
  iconActive: {},
  iconInactive: {},
  iconHover: {},
  iconCurrent: {},
  iconEmpty: {},
  iconFull: {},

  emptyStarContainer: {
    height: "auto",
  },
};

const RatingStar = (props) => {
  const {
    type,
    style,
    iconStyle,
    overrideStyles,
    additionalStyles,
    hover,
    position,
    displayValue,
    calculateStarStyle,
    variation,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { starClassName, iconClassName } = useMemo(() => {
    if (type === "empty") {
      return {
        starClassName: css(
          overrideStyles && overrideStyles.star
            ? overrideStyles.star
            : styles.star,
          additionalStyles && additionalStyles.star,
          overrideStyles && overrideStyles[style]
            ? overrideStyles[style]
            : styles[style],
          additionalStyles && additionalStyles[style],
          overrideStyles && overrideStyles.starEmpty
            ? overrideStyles.starEmpty
            : styles.starEmpty,
          additionalStyles && additionalStyles.starEmpty
        ),
        iconClassName: css(
          overrideStyles && overrideStyles.icon
            ? overrideStyles.icon
            : styles.icon,
          additionalStyles && additionalStyles.icon,
          overrideStyles && overrideStyles[iconStyle]
            ? overrideStyles[iconStyle]
            : styles[iconStyle],
          additionalStyles && additionalStyles[iconStyle],
          overrideStyles && overrideStyles.iconEmpty
            ? overrideStyles.iconEmpty
            : styles.iconEmpty,
          additionalStyles && additionalStyles.iconEmpty
        ),
      };
    }

    const starStyle = calculateStarStyle
      ? calculateStarStyle(displayValue)
      : StyleSheet.create({
          starActive: { color: calcStarColor(displayValue) },
        });
    const rateFloor = Math.floor(displayValue);
    const active = position + 1 <= rateFloor;
    const current = position + 1 === rateFloor;

    return {
      starClassName: css(
        overrideStyles && overrideStyles.star
          ? overrideStyles.star
          : styles.star,
        additionalStyles && additionalStyles.star,
        overrideStyles && overrideStyles[style]
          ? overrideStyles[style]
          : styles[style],
        additionalStyles && additionalStyles[style],
        overrideStyles && overrideStyles.starFull
          ? overrideStyles.starFull
          : styles.starFull,
        additionalStyles && additionalStyles.starFull,
        variation && styles[`${variation}Variation`],
        active &&
          (overrideStyles && overrideStyles.starActive
            ? overrideStyles.starActive
            : styles.starActive),
        active && starStyle.starActive,
        additionalStyles && active && additionalStyles.starActive,
        !active &&
          (overrideStyles && overrideStyles.starInactive
            ? overrideStyles.starInactive
            : styles.starInactive),
        additionalStyles && !active && additionalStyles.starInactive,
        hover &&
          (overrideStyles && overrideStyles.starHover
            ? overrideStyles.starHover
            : styles.starHover),
        additionalStyles && hover && additionalStyles.starHover,
        current &&
          (overrideStyles && overrideStyles.starCurrent
            ? overrideStyles.starCurrent
            : styles.starCurrent),
        additionalStyles && current && additionalStyles.starCurrent
      ),
      iconClassName: css(
        overrideStyles && overrideStyles.icon
          ? overrideStyles.icon
          : styles.icon,
        additionalStyles && additionalStyles.icon,
        overrideStyles && overrideStyles[iconStyle]
          ? overrideStyles[iconStyle]
          : styles[iconStyle],
        additionalStyles && additionalStyles[iconStyle],
        overrideStyles && overrideStyles.iconFull
          ? overrideStyles.iconFull
          : styles.iconFull,
        additionalStyles && additionalStyles.iconFull,
        active &&
          (overrideStyles && overrideStyles.iconActive
            ? overrideStyles.iconActive
            : styles.iconActive),
        active && starStyle.iconActive,
        additionalStyles && active && additionalStyles.iconActive,
        !active &&
          (overrideStyles && overrideStyles.iconInactive
            ? overrideStyles.iconInactive
            : styles.iconInactive),
        additionalStyles && !active && additionalStyles.iconInactive,
        hover &&
          (overrideStyles && overrideStyles.iconHover
            ? overrideStyles.iconHover
            : styles.iconHover),
        additionalStyles && hover && additionalStyles.iconHover,
        current &&
          (overrideStyles && overrideStyles.iconCurrent
            ? overrideStyles.iconCurrent
            : styles.iconCurrent),
        additionalStyles && current && additionalStyles.iconCurrent
      ),
    };
  }, [
    styles,
    type,
    style,
    iconStyle,
    overrideStyles,
    additionalStyles,
    hover,
    position,
    displayValue,
    calculateStarStyle,
    variation,
  ]);

  return (
    <div className={starClassName}>
      <FontAwesomeIcon className={iconClassName} icon={faStar} fixedWidth />
    </div>
  );
};

RatingStar.propTypes = {
  type: PropTypes.string,
  style: PropTypes.string,
  hover: PropTypes.bool,
  position: PropTypes.number,
  displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconStyle: PropTypes.object,
  overrideStyles: PropTypes.object,
  additionalStyles: PropTypes.object,
  calculateStarStyle: PropTypes.object,
  variation: PropTypes.string,
};

RatingStar.defaultProps = {
  type: null,
  style: null,
  hover: false,
  position: 0,
  displayValue: null,
  iconStyle: null,
  overrideStyles: null,
  additionalStyles: null,
  calculateStarStyle: null,
  variation: null,
};

export default compose(withHover())(memo(RatingStar));
