import { faFrown } from "@fortawesome/pro-solid-svg-icons/faFrown";
import { faGrinStars } from "@fortawesome/pro-solid-svg-icons/faGrinStars";
import { faMeh } from "@fortawesome/pro-solid-svg-icons/faMeh";
import { faSmile } from "@fortawesome/pro-solid-svg-icons/faSmile";

export const getRatingDetails = (score, styles) => {
  if (score < 4) {
    return {
      color: styles.red,
      message: "Poor fit",
      icon: faFrown,
    };
  } else if (score < 6) {
    return {
      color: styles.orange,
      message: "Weak fit",
      icon: faMeh,
    };
  } else if (score < 8) {
    return {
      color: styles.green,
      message: "Good fit",
      icon: faSmile,
    };
  } else {
    return {
      color: styles.green,
      message: "Great fit",
      icon: faGrinStars,
    };
  }
};
