import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getRatingDetails } from "./utils";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
const baseStyles = {
  card: {
    padding: "1rem",
    borderRadius: "0.5rem",
    border: "0.5px solid",
    boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)",
    maxWidth: "29.063rem",
  },
  header: {
    ...gStyles.avalonBold,
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "0.5rem",
  },
  icon: {
    fontSize: "2rem",
  },
  score: {
    margin: 0,
  },
  message: {
    ...gStyles.fontBold,
    fontSize: "0.813rem",
  },
  description: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
  },
  red: {
    backgroundColor: colours.newLightRed,
    borderColor: colours.tickerDown,
    color: colours.tickerDown,
  },
  orange: {
    backgroundColor: colours.lightOrange,

    borderColor: colours.moderateConfidence,
    color: colours.moderateConfidence,
  },
  green: {
    backgroundColor: colours.subtleGreen,
    borderColor: colours.highConfidence,
    color: colours.highConfidence,
  },
  dot: {
    fontSize: "0.813rem",
  },
  campaignHeader: {
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    textTransform: "capitalize",
    color: colours.black,
  },
  campaignTitle: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.black,
  },
};

export const RatingCard = (props) => {
  const {
    score,
    description,
    campaignTitle,
    hideTitle,
    hideDescription,
    type,
  } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const { color, message, icon } = getRatingDetails(score, styles);

  return (
    <div className={css(styles.card, color)}>
      <div className={css(styles.header)}>
        <FontAwesomeIcon icon={icon} className={css(styles.icon)} />
        <h3 className={css(styles.score)}>{`${score} out of 10`}</h3>
      </div>

      {!hideTitle && (
        <div>
          <span className={css(styles.campaignHeader)}>{type}</span> <br />
          <span className={css(styles.campaignTitle)}>{campaignTitle}</span>
        </div>
      )}

      {!hideDescription && (
        <>
          <span className={css(styles.message)}>
            <FontAwesomeIcon className={css(styles.dot)} icon={faCircle} />{" "}
            {message}
          </span>{" "}
          <span className={css(styles.description)}>{description}</span>
        </>
      )}
    </div>
  );
};

export default RatingCard;
