import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const SpreadTheWordPanel = Loadable(() =>
  import(/* webpackChunkName: "SpreadTheWordPanel" */ "./SpreadTheWordPanel")
);

function SpreadTheWordPanelAsyncLoading(props) {
  return <LoadableLoading {...props} transparent page />;
}

const SpreadTheWordPanelContainer = (props) => {
  return (
    <SpreadTheWordPanel
      {...props}
      fallback={<SpreadTheWordPanelAsyncLoading />}
    />
  );
};

export default SpreadTheWordPanelContainer;
