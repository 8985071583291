import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useRef, useCallback } from "react";
import Rating from "react-rating";

import RatingStar from "components/Rating/Steps/RatingStep/RatingStar";

import { indexKey } from "utils/misc";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ratingStars: {},
};

const STARS = [
  { label: "Hated It", css: "starOne" },
  { label: "Disliked It", css: "starTwo" },
  { label: "Neutral", css: "starThree" },
  { label: "Liked It", css: "starFour" },
  { label: "Loved It", css: "starFive" },
];

const RatingStars = (props) => {
  const {
    rating,
    onChange,
    emptyOverrideStyles,
    fullOverrideStyles,
    emptyAdditionalStyles,
    fullAdditionalStyles,
    variation,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const fullSymbol = useRef(
    STARS.map((star, i) => (
      <RatingStar
        key={indexKey(i)}
        type="full"
        style={star.css}
        position={i}
        overrideStyle={fullOverrideStyles}
        additionalStyles={fullAdditionalStyles}
        variation={variation}
      />
    ))
  ).current;
  const emptySymbol = useRef(
    STARS.map((star, i) => (
      <RatingStar
        key={indexKey(i)}
        type="empty"
        style={star.css}
        position={i}
        overrideStyles={emptyOverrideStyles}
        additionalStyles={emptyAdditionalStyles}
      />
    ))
  ).current;

  const handleChange = useCallback(
    (newRating) => {
      if (newRating > 0) {
        onChange(newRating);
      }
    },
    [onChange]
  );
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  return (
    <div className={css(styles.ratingStars)} onClick={stopPropagation}>
      <Rating
        emptySymbol={emptySymbol}
        fullSymbol={fullSymbol}
        onChange={handleChange}
        initialRating={rating}
        passDisplayValue
      />
    </div>
  );
};

RatingStars.propTypes = {
  onChange: PropTypes.func,
  rating: PropTypes.number,
  emptyOverrideStyles: PropTypes.object,
  fullOverrideStyles: PropTypes.object,
  emptyAdditionalStyles: PropTypes.object,
  fullAdditionalStyles: PropTypes.object,
  variation: PropTypes.string,
};
RatingStars.defaultProps = {
  onChange: null,
  rating: 0,
  emptyOverrideStyles: null,
  fullOverrideStyles: null,
  emptyAdditionalStyles: null,
  fullAdditionalStyles: null,
  variation: null,
};

export default memo(RatingStars);
