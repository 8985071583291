import { css } from "aphrodite";
import { Fragment, useMemo } from "react";

import StandardButton from "components/Buttons/StandardButton";
import SideButtonStyles from "pages/PodcastView/Sidebar/SideButtonStyles";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ...SideButtonStyles,
};

const ASANALINK =
  "https://form.asana.com/?k=d_mbu5C_ZjqbQe7danPtVg&d=1150101470907462";

const ReportDouble = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const { podcastId } = props;

  const gaProps = useMemo(() => ({ podcastId }), [podcastId]);

  return (
    <Fragment>
      <div className={css(styles.button)} data-testid="reportDoubleButton">
        <StandardButton
          dataid="reportDoubleAnchor"
          label={"Seeing Double?"}
          onClick={null}
          variation="white"
          flat
          gaAction="reportDoubleClicked"
          gaProps={gaProps}
          anchor
          href={ASANALINK}
          target="_blank"
          rel="noopener"
        />
      </div>
      <div className={css(styles.description)}>
        Report this page as a duplicate.
      </div>
    </Fragment>
  );
};

export default ReportDouble;
