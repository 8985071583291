import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const loadSpecificUser = createRoutine("LOAD_SPECIFIC_USER");
export const loadSpecificUserPromise = promisifyRoutine(loadSpecificUser);

export const loadLoggedInUser = createRoutine("LOAD_LOGGED_IN_USER");
export const loadLoggedInUserPromise = promisifyRoutine(loadLoggedInUser);

export const confirmFollowRequest = createRoutine("CONFIRM_FOLLOW_REQUEST");
export const confirmFollowRequestPromise =
  promisifyRoutine(confirmFollowRequest);

export const ignoreFollowRequest = createRoutine("IGNORE_FOLLOW_REQUEST");
export const ignoreFollowRequestPromise = promisifyRoutine(ignoreFollowRequest);

export const sendDirectRecommendation = createRoutine(
  "SEND_DIRECT_RECOMMENDATION"
);
export const sendDirectRecommendationPromise = promisifyRoutine(
  sendDirectRecommendation
);

export const userRecommendationResponse = createRoutine(
  "USER_RECOMMENDATION_RESPONSE"
);
export const userRecommendationResponsePromise = promisifyRoutine(
  userRecommendationResponse
);

export const deleteUserRecommendation = createRoutine(
  "DELETE_USER_RECOMMENDATION"
);
export const deleteUserRecommendationPromise = promisifyRoutine(
  deleteUserRecommendation
);

export const dismissEarnedBadge = createRoutine("DISMISS_EARNED_BADGE");
export const dismissEarnedBadgePromise = promisifyRoutine(dismissEarnedBadge);

export const loadSpecificUserBadge = createRoutine("LOAD_SPECIFIC_USER_BADGES");
export const loadSpecificUserBadgePromise = promisifyRoutine(
  loadSpecificUserBadge
);

export const tellMeWhyRequest = createRoutine("TELL_ME_WHY");
export const tellMeWhyRequestPromise = promisifyRoutine(tellMeWhyRequest);

export const loadTellMeWhyList = createRoutine("LOAD_TELL_ME_WHY_lIST");
export const loadTellMeWhyListPromise = promisifyRoutine(loadTellMeWhyList);

export const deleteCampaign = createRoutine("DELETE_CAMPAIGN");
export const deleteCampaignPromise = promisifyRoutine(deleteCampaign);

export const loadTellMeWhyPromptResponse = createRoutine(
  "LOAD_PROMPT_REPSONSE"
);
export const loadTellMeWhyPromptResponsePromise = promisifyRoutine(
  loadTellMeWhyPromptResponse
);
