import TellMeWhySparkle from "./images/TellMeWhySparkle.svg";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const baseStyles = {
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    whiteSpace: "nowrap",
    gap: "0.5rem",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colours.darkPurple,
    borderRadius: "0.813rem",
    width: "1.125rem",
    height: "1.125rem",
  },
  sparkle: {
    width: "0.75rem",
    height: "0.75rem",
  },
};

const TellMeWhyIconAndText = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.headerWrapper)}>
      <div className={css(styles.circle)}>
        <img
          className={css(styles.sparkle)}
          src={TellMeWhySparkle}
          alt="tell me why sparkle"
        />
      </div>
      <span>Tell Me Why </span>
    </div>
  );
};

export default TellMeWhyIconAndText;
