import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import PageCTABox from "components/Entities/Page/PageCTABox";
import PageSidebar from "components/Entities/Page/PageSidebar";
import PodcastModeratorTools from "components/Podcast/ModeratorTools/LazyLoader";
import RefreshPodcastFeed from "pages/PodcastView/Sidebar/RefreshPodcastFeed";
import SideButtonStyles from "pages/PodcastView/Sidebar/SideButtonStyles";
import RequestContext from "pages/RequestContext";

import ApiCTA from "../ApiCTA";
import ClaimPodcast from "./ClaimPodcast";
import DirectPodcastRecommendations from "./DirectPodcastRecommendations";
import Followers from "./Followers";
import PodcastAffiliates from "./PodcastAffiliatesAsync";
import ProConcierge from "./ProConciergeAsync";
import ProNotes from "./ProNotes";
import ReportDouble from "./ReportDouble";
import SocialLinks from "./SocialLinks";
import ColdCaseAdContainer, {
  canShowColdCaseAd,
} from "./SponsoredContent/ColdCaseAdContainer";
import GoldingsAdContainer, {
  canShowGoldingsAd,
} from "./SponsoredContent/GoldingsAdContainer";
import SpreadTheWord from "./SpreadTheWord";
import Stats from "./Stats";
import Tags from "./Tags";

import affiliates from "constants/affiliates";
import { matchesRouteWithParams } from "utils/url";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import useIsModerator from "hooks/useIsModerator";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useUserIsPodcastOwner from "hooks/useUserIsPodcastOwner";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  sharePodcastPlaceholder: {
    borderRadius: 20,
    [ScreenSizes.lgAndAbove]: {
      height: "10.063rem",
      width: "100%",
      marginBottom: "3rem",
    },
  },
  podcastDetailsPlaceholder: {
    [ScreenSizes.lgAndAbove]: {
      minHeight: "10.688rem",
      width: "100%",
    },
  },
  desktopOnlyContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
};

const PodcastViewSidebar = ({ podcast }) => {
  const { styles } = useStyles([baseStyles]);

  const requestContext = useContext(RequestContext);

  const { isModerator } = useIsModerator();
  const userIsPodcastOwner = useUserIsPodcastOwner(
    podcast && podcast.get("id")
  );

  const hasProPermission = useUserHasPro();

  const hasProPodcastPermission = useHasProForPodcast(podcast.get("id"));

  const location = useLocation();

  const isOnInsightsTab = useMemo(
    () => matchesRouteWithParams(location.pathname, "/podcasts/:id/insights"),
    [location]
  );

  const provider = podcast && podcast.get("hosting_provider");
  const hasHostingProvider =
    provider && affiliates.find((aff) => aff.id === provider);

  const renderPodcastOwnerSections = () => (
    <LazyLoadComponent
      key="ClaimPodcast"
      renderOnMount={requestContext.isBotCrawler}
    >
      <ClaimPodcast key="claim" podcast={podcast} />
      <RefreshPodcastFeed
        podcast={podcast}
        styles={
          userIsPodcastOwner && {
            description: {
              ...SideButtonStyles.descriptionWithBottomMargin,
            },
          }
        }
        key="refresh"
      />
      <ReportDouble
        podcastId={podcast?.get("id")}
        key="reportDouble"
        styles={{ description: { ...SideButtonStyles.descriptionLast } }}
      />
    </LazyLoadComponent>
  );

  return (
    <PageSidebar>
      <SpreadTheWord key="spreadtheword" podcast={podcast} />
      <div className={css(styles.desktopOnlyContainer)}>
        <ApiCTA />
      </div>
      {canShowColdCaseAd(podcast) && (
        <LazyLoadComponent renderOnMount={requestContext.isBotCrawler}>
          <ColdCaseAdContainer podcastId={podcast?.get("id")} />
        </LazyLoadComponent>
      )}
      {userIsPodcastOwner && renderPodcastOwnerSections()}
      {hasProPodcastPermission && <ProNotes key="proNotes" podcast={podcast} />}
      {hasProPermission && (
        <ProConcierge key="proConcierge" podcastId={podcast.get("id")} />
      )}
      <div className={css(styles.sharePodcastPlaceholder)}>
        <DirectPodcastRecommendations podcast={podcast} key="recommendations" />
      </div>
      <Followers podcast={podcast} key="followers" renderOnMount />
      {isModerator && (
        <LazyLoadComponent
          key="isModerator"
          renderOnMount={requestContext.isBotCrawler}
        >
          <PodcastModeratorTools
            podcast_id={podcast.get("id")}
            key="modtools1"
          />
        </LazyLoadComponent>
      )}
      <PageCTABox key="ctabox" sidebarPanelProps={{ renderOnMount: true }} />
      {hasHostingProvider && (
        <LazyLoadComponent
          key="PodcastAffiliates"
          renderOnMount={requestContext.isBotCrawler}
        >
          <PodcastAffiliates podcast={podcast} key="affiliates" />
        </LazyLoadComponent>
      )}
      <LazyLoadComponent
        key="SocialLinks"
        renderOnMount={requestContext.isBotCrawler}
      >
        <SocialLinks key="sociallinksPro" podcastId={podcast.get("id")} />
      </LazyLoadComponent>
      {!isOnInsightsTab && (
        <div className={css(styles.podcastDetailsPlaceholder)}>
          <LazyLoadComponent
            key="Stats"
            renderOnMount={requestContext.isBotCrawler}
          >
            <Stats key="stats" podcast={podcast} />
          </LazyLoadComponent>
        </div>
      )}
      {canShowGoldingsAd(podcast) && (
        <LazyLoadComponent
          key="ExternalPodcastSponsoredContent"
          renderOnMount={requestContext.isBotCrawler}
        >
          <GoldingsAdContainer podcastId={podcast?.get("id")} />
        </LazyLoadComponent>
      )}
      {!isOnInsightsTab && (
        <LazyLoadComponent
          key="Tags"
          renderOnMount={requestContext.isBotCrawler}
        >
          <Tags key="tags" podcast={podcast} />
        </LazyLoadComponent>
      )}
      {!userIsPodcastOwner && renderPodcastOwnerSections()}
    </PageSidebar>
  );
};

PodcastViewSidebar.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};

export default PodcastViewSidebar;
