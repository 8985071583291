import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  pageSidebar: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    top: "-1rem",
    maxWidth: "100%",
  },
};

const PageSidebar = (props) => {
  const { children } = props;
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.pageSidebar)}>{children}</div>;
};

PageSidebar.propTypes = {
  children: PropTypes.node,
};
PageSidebar.defaultProps = {
  children: null,
};

export default PageSidebar;
