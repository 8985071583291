import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import TellMeWhyIconAndText from "./TellMeWhyIconAndText";
import RatingCard from "./TellMeWhyResults/RatingCard";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {},
  header: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    whiteSpace: "nowrap",
    gap: "0.5rem",
    marginBottom: "1rem",
  },
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colours.darkPurple,
    borderRadius: "0.813rem",
    width: "1.125rem",
    height: "1.125rem",
  },
  sparkle: {
    width: "0.75rem",
    height: "0.75rem",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    whiteSpace: "nowrap",
    gap: "0.5rem",
  },
  loading: {
    ...gStyles.skeletonPlaceholder,
    maxWidth: "29.063rem",
    height: "6.625rem",
    width: "100%",
  },
};

const CardStyleOverride = {
  header: {
    fontSize: "1.125rem",
    gap: "0.5rem",
  },
  icon: {
    fontSize: "1.375rem",
  },
};

const ButtonStyles = {
  button: {
    width: "3.375rem",
    height: "1.5rem",
    margin: 0,
    marginLeft: "auto",
  },
};

const TellMeWhyResultSidePanel = (props) => {
  const { podcastId, tellMyWhyPrompt } = props;
  const { styles, css } = useStyles(baseStyles);

  const user = useLoggedInUser();

  const username = user?.get("username");
  const userEmail = user?.getIn(["user_data", "email"]);
  const { showModal, closeModal } = useActionCreators({
    showModal: modalActions.showModal,
    closeModal: modalActions.closeModal,
  });

  const firstPrompt = tellMyWhyPrompt?.get(0);
  const fitScore =
    firstPrompt?.get("fit_score") ||
    firstPrompt?.getIn(["response", 0, "fit_score"]);
  const fitDescription = firstPrompt?.get("fit_description");
  const prompt = firstPrompt?.get("prompt");
  const title = firstPrompt?.get("title") || prompt?.get("title");
  const type = firstPrompt?.get("type") || prompt?.get("type");

  const handleTellMeWhyClick = useCallback(() => {
    sendGAEvent({
      action: "tellmewhy-launch",
      page: "Podcast Page",
      podcastId,
      username,
      userEmail,
    });

    showModal("tellMyWhyPrompt", {
      closeModal,
      podcastId,
      step: "results",
    });
  }, [closeModal, podcastId, showModal, userEmail, username]);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        <TellMeWhyIconAndText />

        <StandardButton
          label="Open"
          variation="white"
          customStyles={ButtonStyles}
          onClick={handleTellMeWhyClick}
        />
      </div>
      {!firstPrompt ? (
        <div className={css(styles.loading)} />
      ) : (
        <RatingCard
          styles={CardStyleOverride}
          score={fitScore}
          description={fitDescription}
          type={type}
          campaignTitle={title}
          hideDescription
        />
      )}
    </div>
  );
};

export default TellMeWhyResultSidePanel;

TellMeWhyResultSidePanel.propTypes = {
  podcastId: PropTypes.number,
  tellMyWhyPrompt: PropTypes.instanceOf(Map).isRequired,
};
